import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

// Temporary Water Run Protection
// Domestic Cold Water Protection - Supply
// Domestic Cold Water Protection - Risers
// Domestic Cold Water Protection - Branches / Zones
// Sprinkler Supply Line Protection
// Mechanical Room Protection
// Water Intrustion
// Electrical Room Protection
// Electrical Riser Water Barrier Protection
// Elevator Pit Protection
// Hoist Suite Protection
// IoT Controls (OTHER)
// Standalone Connectivity
// Installation, Commissioning & Ongoing Maintenance

const data = [
  { deviceName: "Temporary Water Run Protection", value: true },
  { deviceName: "Domestic Cold Water Protection - Supply", value: true },
  { deviceName: "Domestic Cold Water Protection - Risers", value: true },
  { deviceName: "Domestic Cold Water Protection - Branches / Zones", value: true },
  { deviceName: "Sprinkler Supply Line Protection", value: true },
  { deviceName: "Mechanical Room Protection", value: true },
  { deviceName: "Electrical Room Protection", value: true },
  { deviceName: "Electrical Riser Water Barrier Protection", value: true },
  { deviceName: "Elevator Pit Protection", value: true },
  { deviceName: "Hoist Suite Protection", value: true },
  { deviceName: "Standalone Connectivity", value: true },
  { deviceName: "Installation, Commissioning & Ongoing Maintenance", value: true },
]

const BrickEyeServices = () => {
  return (
    <TableContainer component={Paper} sx={{ maxWidth: 700, margin: "auto", mt: 5, borderRadius: 2 }}>
      <Typography variant="h6" align="center" sx={{ my: 2 }}>
        <img src="https://static.wixstatic.com/media/17276b_6dd79bb258024bbfb2f6a62b3f3abc5f~mv2.png/v1/fill/w_189,h_40,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Flat%20BlackTxt_3x_edited.png" />
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{
              paddingLeft: 30,
            }}>Device</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell style={{ paddingLeft: 30 }}>{row.deviceName}</TableCell>
              <TableCell align="center">
                {typeof row.value === "boolean" ? (
                  row.value ? (
                    <CheckCircleIcon color="success" />
                  ) : (
                    <CancelIcon color="error" />
                  )
                ) : (
                  row.value
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default BrickEyeServices;
