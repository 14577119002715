import React, { Fragment, useState } from "react";

import PageLayout from "layouts/PageLayout";
import {
  ArrowUp,
  HeaderPanel,
  IconWrapper,
  Line,
  Title,
  TitleButton,
  TitleText,
} from "./styles";
import BrickEyeServices from "modules/partners/components/BrickeyeServices";

const Partners = () => {
  const [isWaterDevicesOpen, setIsWaterDevicesOpen] = useState(false);
  const [isWeatherOpen, setIsWeatherOpen] = useState(false);
  const waterDevicesRef = React.useRef(null);
  const weatherRef = React.useRef(null);

  const scrollTo = (anchor) => {
    setTimeout(() => {
      anchor.current.scrollIntoView({
        behavior: "smooth",
      });
    }, 0);
  };

  const toggleProjectForm = () => {
    setIsProjectFormOpen(!isProjectFormOpen);
  };

  const toggleWaterDevicesOpen = () => {
    setIsWaterDevicesOpen(!isWaterDevicesOpen);
  };

  const toggleWeatherOpen = () => {
    setIsWeatherOpen(!isWeatherOpen);
  };

  return (
    <>
      <PageLayout>
        <div>Hi</div>
        <Fragment key="title">
          <HeaderPanel>
            <Title>Partners</Title>
          </HeaderPanel>
        </Fragment>
        <Fragment key="content">
          <TitleButton onClick={toggleWaterDevicesOpen}>
            <TitleText ref={weatherRef}>Water Devices</TitleText>
            <Line />
            <IconWrapper isOpened={isWaterDevicesOpen}>
              <ArrowUp />
            </IconWrapper>
          </TitleButton>
          {isWaterDevicesOpen && <BrickEyeServices />}
          <TitleButton onClick={toggleWeatherOpen}>
            <TitleText ref={waterDevicesRef}>Weather</TitleText>
            <Line />
            <IconWrapper isOpened={isWeatherOpen}>
              <ArrowUp />
            </IconWrapper>
          </TitleButton>
          {isWeatherOpen && <div>Monarch</div>}
        </Fragment>
      </PageLayout>
    </>
  );
};

export default Partners;
