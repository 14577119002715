import { ROLES_IDS } from 'services/AuthService';

export const PROJECTS_TABS = [
  {
    name: 'details',
    label: 'PROJECT DETAILS',
  },
  {
    name: 'location',
    label: 'LOCATION',
  },
  {
    name: 'notes',
    label: 'NOTES',
  },
  {
    name: 'documents',
    label: 'DOCUMENTS',
  },
  {
    name: 'contacts',
    label: 'CONTACTS',
  },
  {
    name: 'inspections',
    label: 'INSPECTIONS',
  },
  {
    name: 'budget',
    label: 'BUDGET',
  },
  {
    name: 'feeProposal',
    label: 'FEE PROPOSAL',
    disabledTooltip: {
      [ROLES_IDS.ICCoordinator]: 'This feature is unavailable for Lite package subscribers',
      [ROLES_IDS.ICUnderwriter]: 'This feature is unavailable for Lite package subscribers',
    },
  },
  {
    name: 'resources',
    label: 'RESOURCES',
  },
  {
    name: 'report-notes',
    label: 'REPORT NOTES',
    disabledTooltip: {
      [ROLES_IDS.SiteContact]: 'This section will become available when a report has been generated.',
      [ROLES_IDS.InsuranceRepresentative]: 'This section will become available when a report has been generated.',
      [ROLES_IDS.Broker]: 'This section will become available when a report has been generated.',
      [ROLES_IDS.RiskManager]: 'This section will become available when a report has been generated.',
    },
  },
  {
    name: 'recommendations',
    label: 'RECOMMENDATIONS',
  },
  {
    name: 'claims',
    label: 'CLAIMS',
  },
  {
    name: 'expenses',
    label: 'TIME & EXPENSES',
  },
  {
    name: 'var',
    label: 'Value At Risk',
  },
  {
    name: 'analytics',
    label: 'ANALYTICS',
    disabledTooltip: {
      [ROLES_IDS.RiskEngineer]: 'This section will become available when Report Notes are populated and the recommendations are issued for the first inspection.',
      others: 'This section will become available after the first inspection.',
    },
  },
  {
    name: 'reports',
    label: 'REPORTS',
  },
  {
    name: 'devices',
    label: 'DEVICES',
  },
];
