import React, { useState, useEffect } from "react";
import cx from "classnames";
import { useLocation, useParams } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Icon from "components/Icon";
import AuthService, { ROLES_IDS } from "services/AuthService";
import { analyticsAvailable } from "projects/store/analytics.selectors";
import * as AnalyticsActions from "projects/store/analytics.reducer";
import { PACKAGES_IDS } from "modules/subscriptions/constants";

import { PROJECTS_TABS } from "./projectTabs";
import {
  NavTabs,
  NavTab,
  NavLabel,
  IconWrapper,
  SelectedBorder,
  NavItemWrapper,
} from "./styles";
import ReportNotesService from "../../modules/projects/api/ReportNotesService";
import ProjectService from "modules/projects/api/ProjectService";
import { currentProject } from "modules/projects/store/projects.selectors";
import { BASE_PROJECT_TYPE } from "types/project";

const NavItem = ({ icon, label, selected, disabled }) => {
  const { projectId } = useParams();
  const className = cx(disabled && "disabled", selected && "selected");

  return (
    <NavTab className={className} to={`/projects/${projectId}/${icon}`}>
      <IconWrapper>
        <Icon icon={icon} />
      </IconWrapper>
      <NavLabel className="nav-label">{label}</NavLabel>
      <SelectedBorder selected={selected} className="selected-border" />
    </NavTab>
  );
};

const defaultTab = "details";

const Navbar = () => {
  const { projectId } = useParams();
  const location = useLocation();
  const tab = location.pathname.split("/").at(-1);
  const { role, subscription } = AuthService.getUser();
  const [currTab, setCurrTab] = useState(tab || defaultTab);
  const [isReportNotesAvailable, setIsReportNotesAvailable] = useState(false);
  const [tabAccess, setTabAccess] = useState([]);
  const project = useSelector(currentProject);

  const isPackageLite = subscription === PACKAGES_IDS.Lite;

  const dispatch = useDispatch();

  const getReportNotesAvailable = async () => {
    if (role === ROLES_IDS.RiskEngineer) {
      setIsReportNotesAvailable(true);
    } else if (
      role === ROLES_IDS.RiskManager ||
      role === ROLES_IDS.Broker ||
      role === ROLES_IDS.SiteContact ||
      role === ROLES_IDS.InsuranceRepresentative
    ) {
      const response = await ReportNotesService.isTabAvailable(projectId);
      setIsReportNotesAvailable(response.data.data);
    } else {
      setIsReportNotesAvailable(false);
    }
  };
  const getAnalyticsAvailable = async () => {
    const response = await dispatch(AnalyticsActions.hasAccess(projectId));
    if (response.fieldErrors?.ProjectId) {
      window.location.replace("/projects");
    }
  };
  const isAnalyticsAvailable = useSelector(analyticsAvailable);

  useEffect(() => {
    getAnalyticsAvailable();
  }, []);

  useEffect(() => {
    getReportNotesAvailable();
  }, []);

  const getTabAccess = async () => {
    if (projectId == null) {
      return;
    }
    const response = await ProjectService.tabAccess(projectId);
    setTabAccess(response.data.data);
    AuthService.setCompanyProjectTabAccess(response.data.data);
  };
  useEffect(() => {
    getTabAccess();
  }, []);

  useEffect(() => {
    setCurrTab(tab || defaultTab);
  }, [tab]);

  // disabling all Navbar items on /projects/add
  const isAllDisabled = projectId === undefined;

  const permittedTabs = PROJECTS_TABS.filter(({ name }) =>
    AuthService.hasPermission(name, "projects", tabAccess)
  ).map((tabItem) => {
    if (tabItem.name === "analytics") {
      return { ...tabItem, disabled: !isAnalyticsAvailable };
    }
    if (tabItem.name === "feeProposal") {
      return { ...tabItem, disabled: isPackageLite };
    }
    if (tabItem.name === "report-notes") {
      return { ...tabItem, disabled: !isReportNotesAvailable };
    }
    if (tabItem.name === "var") {
      if (project.type !== BASE_PROJECT_TYPE.OPERATIONAL_PROPERTY)
        return { ...tabItem, remove: true };
    }
    return tabItem;
  }).filter(({ remove }) => !remove);

  return (
    <NavTabs>
      {permittedTabs.map(({ name, label, disabled, disabledTooltip }) => {
        if (disabled && disabledTooltip) {
          const tooltipText = disabledTooltip[role] || disabledTooltip.others;
          return (
            <Tooltip arrow title={tooltipText} key={name}>
              <NavItemWrapper>
                <NavItem icon={name} label={label} disabled />
              </NavItemWrapper>
            </Tooltip>
          );
        }
        return (
          <NavItem
            key={name}
            icon={name}
            label={label}
            disabled={disabled || isAllDisabled}
            selected={currTab === name}
          />
        );
      })}
    </NavTabs>
  );
};

export default Navbar;
